<!-- <appbar>{{ gameName }}</appbar> -->
<!-- <div class="appHeader text-light">
  <div class="left">
    <a (click) = "goBack()" class="headerButton">
        
      <ion-icon name="arrow-back"></ion-icon>
      
   </a> 
  </div>
  <div class="pageTitle">{{ gameName }}</div>
  <div class="right">
    <a class="headerButton">  
      <ion-icon name="refresh-outline" (click) = "refreshPage()"></ion-icon>             
      
    </a>
  </div>
</div> -->
<appbar (myEvent)="refreshPage()" [customBack]="goToCustomRoute">{{ gameName }}</appbar>



<div class="main__wrapper">
  <div class="header">

    <!--Content before waves-->
    <div class="row" *ngIf="!isUserLoggedIn">
      <div class="col-12 my-2 text-left">
        <a (click)="loginAccount()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
          <div style="align-items:center">
            <ion-icon name="person-circle-outline" style="vertical-align:middle ;margin-right:10px"
              class="account-login">
            </ion-icon>
            <span class="account_login_text">{{ 'click_to_login' | translate}}</span>
          </div>
        </a>

      </div>
    </div>

    <div style="padding: 10px 15px;" *ngIf="isUserLoggedIn">
      <a [routerLink]="['/wallet/game-wallet',providerId]" replaceUrl="true" [state]="{parentLink: gameLink}">
        <div class="card" style="background: transparent !important; ">
          <div class="card-body" style="margin: 0px !important; padding: 0px !important;">
            <div class="row">
              <div class="col-8">
                <div class="row walletlbl">
                  <div class="col-12 ml-auto" style="display: flex;">
                    <ion-icon name="wallet-outline" class="active-wallet"></ion-icon>
                    <!-- <img src = './assets/img/activewallet.png'  class="active-wallet"/> -->
                    <span class="child-walletlbl pt-1">{{ 'mainWallet' | translate}} &nbsp;&nbsp;&nbsp;
                      {{this.userProfileBalance | number : 0}}</span>

                  </div>
                </div>

                <div class="row walletlbl">
                  <div class="col-12 ml-auto" style="display: flex;">
                    <img src='./assets/img/game_wallet.png' class="active-wallet" />
                    <span class="child-walletlbl pt-1">{{ gameName }}({{ 'ks' | translate}}) &nbsp;&nbsp;&nbsp;
                      {{this.gameUserBalance}}</span>

                  </div>

                </div>
              </div>
              <div class="col-4 " style="height: 100%;">
                <div class="row"
                  style="height: 100% !important; margin-top: 20% !important; justify-content: right !important;">
                  <ion-icon name="chevron-forward-outline" style="font-size: 30px; color: #fff !important;"></ion-icon>
                </div>

              </div>
              <!-- <div class="col-8 text-left"><app-account-login ></app-account-login> </div>
          <div class="col-4 text-right" style="padding-top: 10px;"><app-language-page ></app-language-page> </div> -->
            </div>
          </div>
        </div>
      </a>
    </div>


    <div class="slider">
      <owl-carousel-o [options]="customOptions">
        <ng-container *ngIf="this.adsList != ''">
          <ng-template carouselSlide *ngFor="let adsObj of this.adsList">
            <div class="slide">
              <img [src]="adsObj.imageUrl" alt="AdsImage">
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>

      <div class="overlay" *ngIf="this.marqueeText != null">
        <marquee direction="left">{{this.marqueeText.marqueeText}}</marquee>
      </div>
    </div>
    <!--Waves Container-->
    <div>
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div>
    <!--Waves end-->
  </div>
  <!-- end header -->

  <!-- App Capsule style="float:left; max-width:30%; max-height:200px;" gameImageList-->
  <div class="row"
    *ngIf="this.gameList != null && this.gameList.isMaintenance == false && this.gameList.status == 'ACTIVE'">
    <!-- <img  *ngIf = "(this.gameList.titleimageUrl !=null && this.gameList.titleimageUrl?.length > 0)" [src] = 'this.gameList.titleimageUrl'
        class ="gameTitleImgClass"> -->
    <div class="container">
      <!-- this.gameList.gsGameList.length -->
      <div class="row" *ngIf="this.gameItems.length > 10">
        <div class="game-searching-col">
          <input type="text" placeholder="Search.." (keyup)="search()" [(ngModel)]="searchKey"
            [ngModelOptions]="{standalone: true}" class="game-searching">
          <ion-icon name="search-outline" class="game-searching-icon"></ion-icon>
        </div>

      </div>
      <div class="row">
        <div class="game-list" *ngFor="let gameObj of this.gameItems">
          <img class="bradius" [src]="gameObj.iconurl" alt="gameImage"
            (click)='showplayFreeOrplay(playFreeOrPlay,transferAlert,gameObj.supportdemourl,gameObj.code,gameObj.providercode,gameObj)'>
          <span class="text-wrap pt-1 ml-2" style="width: 5rem;">{{ changeLanguage(gameObj)}}</span>
        </div>
      </div>
    </div>
  </div>
  <!-- * App Capsule -->
  <!-- App Maintain Capsule -->
  <div class=""
    *ngIf="this.gameList != null  && this.gameList.isMaintenance == true && this.gameAlertObject.status =='ACTIVE'">
    <img [src]='this.gameList.titleimageUrl'
      *ngIf="(this.gameList.titleimageUrl !=null && this.gameList.titleimageUrl?.length > 0)" class="gameTitleImgClass">
    <div class="container" *ngIf="this.gameAlertObject !=null">
      <div class="row">
        <div class="col-4 maintenanceClass pt-2">
          <img *ngIf=" (this.gameAlertObject.imageUrl == null && this.gameAlertObject.imageUrl?.length == 0)"
            src='./assets/img/maintenance.png' width="100px" height="100px" />
          <img *ngIf=" (this.gameAlertObject.imageUrl != null && this.gameAlertObject.imageUrl?.length != 0)"
            [src]='this.gameAlertObject.imageUrl' width="100px" height="100px" class='gameAlertImg' />
        </div>
        <p>{{this.gameAlertObject.description_en}}</p>
      </div>
    </div>

  </div>
  <!-- * App Maintain Capsule -->
</div>



<!-- <div style="position: relative; justify-content: center;" class="mt-2" >
    <ngx-spinner size = "small" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
   </div> -->


<!-- Play free or play Modal -- not user this -->
<div class="modal fade" id="playFreeOrplay" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content playFreeOrPlay">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <button type="button" class="btn btn-success btn-lg btnAction" data-dismiss="modal" (click)="playFree()">{{
            'play_free' | translate}}</button>
        </div>
        <div class="row py-3">
          <button type="button" class="btn btn-success btn-lg btnAction" (click)="play(transferAlert)">{{ 'play' |
            translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- * Play free or play -->

<!-- Pls topup alert -->
<ng-template #transferAlert>
  <div class="modal-header">
    <h4 class="modal-title pull-left"></h4>
    <div class="close pull-right">
      <ion-icon name="close-circle-outline" (click)="HideAlert()" style="color: red;"></ion-icon>
    </div>
    <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="HidePlayFreeOrPlayAlert()">
      <span aria-hidden="true">×</span>
    </button>  -->
  </div>
  <div class="modal-body">
    <div style="text-align: center;">
      <ion-icon name="alert-circle-outline" style="font-size: 50px;"></ion-icon>
    </div>
    <div class="container p-0">
      <p class="text-center my-2">{{ 'gametransactionAlert' | translate}}</p>

      <div class="d-flex align-items-center mb-3">
        <img src='./assets/img/game_kyat.png' class="active-wallet" />
        <span class="child-walletbalance">{{ 'mainWallet' | translate}} &nbsp;&nbsp;&nbsp; {{this.userProfileBalance |
          number : 0}}</span>
      </div>

      <div class="d-flex align-items-center">
        <img src='./assets/img/game_wallet.png' class="active-wallet" />
        <span class="child-walletbalance">{{ 'gameWallet' | translate}} &nbsp;&nbsp;&nbsp;
          {{this.gameUserBalance}}</span>

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary btnGroup" (click)="openGame('free')">{{ 'game_view' |
      translate}}</button>

    <button type="button" class="btn btn-primary btnGroup" (click)="goToGameWallet()">{{ 'game_balance_transfer' |
      translate}}</button>
  </div>

</ng-template>

<!-- playFreeOrPlay alert use this -->
<ng-template #playFreeOrPlay>
  <div class="modal-header">
    <h4 class="modal-title pull-left"></h4>
    <div class="close pull-right">
      <ion-icon name="close-circle-outline" (click)="HidePlayFreeOrPlayAlert()" style="color: red;"></ion-icon>
    </div>
    <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="HidePlayFreeOrPlayAlert()">
        <span aria-hidden="true">×</span>
      </button>  -->
  </div>

  <div class="modal-body">
    <div class="row">
      <button type="button" class="btn btn-block mx-3" data-dismiss="modal" (click)="playFree()"
        style="background: #000; color: #fff;margin: 20px 0px;">
        <ion-icon name="play-circle-outline"></ion-icon>&nbsp;{{ 'play_free' | translate}}
      </button>
      <button type="button" class="btn btn-success btn-block  mx-3" (click)="play(transferAlert)">
        <ion-icon name="play-circle-outline"></ion-icon> &nbsp;{{ 'play' | translate}}
      </button>
    </div>
    <!-- <div class = "row py-3">
      <button type="button" class="btn btn-success btn-lg" (click) = "play()" >{{ 'play' | translate}}</button>
    </div> -->
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm close-buttton mr-1" (click)="HidePlayFreeOrPlayAlert()">
      {{ 'close' | translate}}
    </button>
  </div>
</ng-template>

<!-- * Game maintenance Alert -->

<div *ngIf="this.gameAlertObject!=null && this.gameList.isMaintenance == true && this.gameAlertObject.status =='ACTIVE'"
  id="DialogIconedInfo" class="modal" tabindex="-1" data-backdrop="static" role="dialog">
  <div class="modal-dialog gameAlertContentModal">
    <!-- Modal content-->
    <div class="modal-content ">
      <div class="modal-header">
        <button type="button" class="close closeX" data-dismiss="modal">&times;</button>

      </div>
      <div class="modal-body">
        <div class="text-center"><img [src]='this.gameAlertObject.imageUrl' class="gameAlertImgClass" width="80px">
        </div>
        <p>{{this.gameAlertObject.description_en}}.</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- <div style="position: relative; justify-content: center;" class="mt-2">
    <ngx-spinner size = "small" color = "rgb(6,56,107)" type = "ball-spin-clockwise" [fullScreen] = "false"></ngx-spinner>
</div> -->


<ngx-spinner name="refreshLoading" bdOpacity=0.9 size="medium" color="#00acc1" type="ball-spin-clockwise"
  [fullScreen]="true"></ngx-spinner>
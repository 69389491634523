import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js'; 
import { Md5 } from 'ts-md5';
@Injectable({
  providedIn: 'root'
})
export class FunctService {
  domain: string;
  microsoftAddress(microsoftAddress: any, arg1: { headers: import("@angular/common/http").HttpHeaders; }) {
    throw new Error('Method not implemented.');
  }

  public ipaddress: any;
  public appName: any;
  public secretKey: any;
  public chatSignalrConnect: any;
  public chatSignalrApi: any;
  public signalrInfo: any;
  constructor() {  
    //.Net api
    this.appName="Lucky2d"; 
    //.Net api
   // this.ipaddress = "http://localhost:22735/api/"; 
    //prod
    this.ipaddress = "https://api.lucky2d.com/api/";      
    //testDev        
   //this.ipaddress = "https://demoapi.lucky2d.com/api/";   
    //chatsignalr
    this.chatSignalrConnect= ''; //"https://chatapi.lucky2d.com/chat";
    this.chatSignalrApi = ''; //"https://chatapi.lucky2d.com/api/";

    this.signalrInfo = "https://signalr.lucky2d.com/api/info/";
    this.secretKey="Fl1Ab4Vw5Nc7Oq7Tg4En6Tl2Hc5Lj6Im0Lm1Jj3Gh9R";
    this.domain = "https://www.lucky2d.com/";    
  }

  encrypt(plainText: string): string {      
    var key = CryptoJS.enc.Utf8.parse('7061737323313233');
    var iv = CryptoJS.enc.Utf8.parse('7061737323313233');    
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainText), key,
      {
        keySize: 128,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    //console.log(encrypted.toString());
    return encrypted.toString();
   }

   decrypt(text: string){
    var key = CryptoJS.enc.Utf8.parse('7061737323313233');
    var iv = CryptoJS.enc.Utf8.parse('7061737323313233'); 
    var decryptedData = CryptoJS.AES.decrypt(text, key, {
      keySize: 128,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decryptedData;   
   }
  

   getSignature(signature: string): string{
    var sin= Md5.hashStr(signature).toString();
    return sin;
   }

   decryptTransfer(text: string){
    var key = CryptoJS.enc.Utf8.parse('JKLASD9876543210');
    var iv = CryptoJS.enc.Utf8.parse('JKLASD9876543210'); 
    var decryptedData = CryptoJS.AES.decrypt(text, key, {
      keySize: 128,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return decryptedData;   
   }

   encryptTransfer(text: string){
    var key = CryptoJS.enc.Utf8.parse('JKLASD9876543210');
    var iv = CryptoJS.enc.Utf8.parse('JKLASD9876543210');    
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key,
      {
        keySize: 128,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    //console.log(encrypted.toString());
    return encrypted.toString();
   }

}


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '../../../../service/navigation.service'
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.scss']
})
export class AppbarComponent {
  @Output() myEvent = new EventEmitter();
  @Output() addbtnEvent=new EventEmitter();
  @Input() rootLevel = 1;

  
  @Input() titleonly: boolean=false;
  @Input() customright: boolean=false;
  @Input() refreshIcon: boolean=false;


  isClose = false;
  // @Input() props: { rootLevel: number; parentLink: string; };

  @Input() customBack: (() => void) | null = null; 

  constructor(private navigation: NavigationService) {
  }

  ngOnInit(): void {
    this.isClose = true;
  }

  refreshPage(): void {
    
    this.ngOnInit();
    this.myEvent.emit();
  }

  async goBack() {  
    if (this.customBack) {
      this.customBack(); // Call custom back function if provided
      
    } else {
      this.navigation.back();// Default back navigation
    }  
   
  }

  withdrawAdd(){
    this.addbtnEvent.emit(true);
  }



}